import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/apps/blog/src/components/PageLayout/PageLayout.tsx";
import { BlogList, Container, HomePageHeader, PageOffset, Box } from 'components';
import { graphql } from 'gatsby';
export const homePageQuery = graphql`
    query homePageQuery {
        blog: allMdx(
            sort: { fields: [frontmatter___sort], order: DESC }
            filter: { frontmatter: { published: { eq: true }, kind: { eq: "Blog" } } }
            limit: 3
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                        preview
                        description
                        published
                        slug
                        images {
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        width: 3000
                                        quality: 80
                                        placeholder: BLURRED
                                    )
                                }
                            }
                            isCover
                        }
                    }
                }
            }
        }
        hobby: allMdx(
            sort: { fields: [frontmatter___sort], order: DESC }
            filter: { frontmatter: { published: { eq: true }, kind: { eq: "Hobbies" } } }
            limit: 3
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                        preview
                        description
                        published
                        slug
                        images {
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        width: 3000
                                        quality: 80
                                        placeholder: BLURRED
                                    )
                                }
                            }
                            isCover
                        }
                    }
                }
            }
        }
        project: allMdx(
            sort: { fields: [frontmatter___sort], order: DESC }
            filter: { frontmatter: { published: { eq: true }, kind: { eq: "Projects" } } }
            limit: 3
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                        description
                        slug
                        images {
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        width: 3000
                                        quality: 80
                                        placeholder: BLURRED
                                    )
                                }
                            }
                            isCover
                        }
                    }
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  homePageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HomePageHeader mdxType="HomePageHeader" />
    <h2>{`Latest in Blog`}</h2>
    <BlogList data={props.data.blog.edges} showLink allItemsLinkTemplate="/blog" itemLinkTemplate="/blog/#SLUG#/" mdxType="BlogList" />
    <Box paddingTop={3} paddingBottom={3} mdxType="Box">
      <h2>{`Latest 3D Art`}</h2>
      <BlogList data={props.data.hobby.edges} showOnlyYear showLink showAllLinkText="See all artwork" allItemsLinkTemplate="/hobbies" itemLinkTemplate="/hobbies/#SLUG#/" mdxType="BlogList" />
    </Box>
    <h2>{`Latest Projects`}</h2>
    <BlogList data={props.data.project.edges} showOnlyYear showLink showAllLinkText="See all projects" allItemsLinkTemplate="/projects" itemLinkTemplate="/projects/#SLUG#/" cardImageYPosition={100} mdxType="BlogList" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      